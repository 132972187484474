<template>
    <section class="single-area area-padding mid-top-padding contract-section" style="color: black;">
        <div class="service-fluid">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">

                        <!-- HEADER -->
                        <div id="contract-header">
                            <p class="MsoNoSpacing" style="text-align:center">
                                <span style="font-size:10.0pt;font-family:'Arial',sans-serif">
                                    <strong>CARÁTULA DE COMISIÓN MERCANTIL</strong>
                                </span>
                            </p>
                            <div id="caratula" class="container-fluid">
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <span style="font-size:10.0pt;font-family:'Arial',sans-serif">
                                            <strong>NOMBRE DEL PRODUCTO: Solicitante RedGirasol</strong>
                                        </span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <span style="font-size:10.0pt;font-family:'Arial',sans-serif">
                                            <strong>TIPO DE PRODUCTO: Comisión Mercantil</strong>
                                        </span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-1/3 text-center">
                                        <span
                                            style="font-size:12px;font-family:'Arial',sans-serif;text-align:center;padding: 1rem;">
                                            <strong>IDENTIFICACIÓN DE LA PLATAFORMA</strong><br><br>
                                            <ul style="text-align:start">
                                                <li>Página de internet: <br> <a href="https://www.redgirasol.com"
                                                        target="_blank"
                                                        rel="noopener noreferrer">https://www.redgirasol.com</a></li>
                                            </ul>

                                        </span>
                                    </div>
                                    <div class="vx-col w-1/3 text-center" style="border-left: solid 1px #c2c2c2;border-right: solid 1px #c2c2c2;">
                                        <span
                                            style="font-size: 12px;font-family: Arial, sans-serif;text-align: center;padding: 1rem;">
                                            <strong>MEDIOS DE DEPÓSITO<br><br></strong>
                                            <ul style="text-align:start">
                                                <li>Transferencias Electrónicas</li>
                                                <li>Efectivo a través de Entidades Financieras</li>
                                                <li>Domiliación bancaria</li>
                                            </ul>
                                        </span>
                                    </div>
                                    <div class="vx-col w-1/3 text-center">
                                        <span
                                            style="font-size:12px;font-family:'Arial',sans-serif;text-align:center; padding: 1rem;">
                                            <strong>MEDIOS DE DISPOSICIÓN<br><br></strong>
                                            <ul>
                                                <li>Transferencias Electrónicas</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-full text-center">
                                        <span style="font-size:10.0pt;font-family:'Arial',sans-serif;text-align:center">
                                            <strong>COMISIONES RELEVANTES</strong>
                                        </span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-1/4" style="border-right: solid 1px #c2c2c2;padding: 1rem;">
                                        <ul>
                                            <li>
                                                Por gastos de pre-originación: 2% del
                                                valor del Monto.
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="vx-col w-3/4" style="padding: 1rem;">
                                        <ul>
                                            <li>
                                                Para más información y otras comisiones consulte la cláusula 5.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <span style="font-size:10.0pt;font-family:'Arial',sans-serif;">
                                            <strong>ADVERTENCIAS</strong>
                                        </span> <br>
                                        <ul>
                                            <li>"Los recursos de los Usuarios en las operaciones realizadas con (nombre
                                                de la Institución de Tecnología Financiera)
                                                no se encuentran garantizados por ninguna autoridad";</li> <br>
                                            <li>"Los rendimientos de ésta inversión son estimadas y no están
                                                asegurados"; </li> <br>
                                            <li>“Incumplir tus obligaciones te pueden generar comisiones e intereses
                                                moratorios”.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-full text-center">
                                        <span style="font-size:10.0pt;font-family:'Arial',sans-serif;text-align:center">
                                            <strong>SEGUROS Y SERVICIOS ADICIONALES</strong>
                                        </span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-1/3 text-center">
                                        <span
                                            style="font-size:12px;font-family:'Arial',sans-serif;text-align:start;padding: 1rem;">
                                            Seguro ______ (opcional u obligatorio) <br><br>
                                            Servicio Adicional: _______
                                        </span>
                                    </div>
                                    <div class="vx-col w-1/3 text-center" style="border-left: solid 1px #c2c2c2;border-right: solid 1px #c2c2c2;">
                                        <span
                                            style="font-size: 12px;font-family: Arial, sans-serif;text-align: start;padding: 1rem;">
                                            Aseguradora: <br><br>
                                            Prestado por:
                                        </span>
                                    </div>
                                    <div class="vx-col w-1/3 text-center">
                                        <span
                                            style="font-size:12px;font-family:'Arial',sans-serif;text-align:center; padding: 1rem;">
                                            Cláusula: <br><br>
                                            Cláusula:
                                        </span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <span style="font-size:10.0pt;font-family:'Arial',sans-serif;">
                                            <strong>ESTADO DE OPERACIÓN</strong> <br>
                                            Periodicidad: Mensual <br>
                                            Disponible: Plataforma: <a
                                                href="https://www.redgirasol.com/">https://www.redgirasol.com/</a>
                                        </span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <span style="font-size:10.0pt;font-family:'Arial',sans-serif;">
                                            <strong>Aclaraciones y reclamaciones</strong> <br>
                                            Unidad Especializada de Atención a Usuarios: Jorge Armando Cárdenas Tijerina
                                            <br>
                                            Correo electrónico: <a
                                                href="mailto:une@redgirasol.com">une@redgirasol.com</a> <br> <br>

                                            Página de Internet: <a href="https://www.redgirasol.com/aclaraciones"
                                                target="_blank">https://www.redgirasol.com/aclaraciones</a>
                                        </span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <span style="font-size:10.0pt;font-family:'Arial',sans-serif;">
                                            <strong>Registro de Contratos de Adhesión Núm:
                                                15895-452-041749/01-00560-0325</strong> <br>
                                            Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios
                                            Financieros (CONDUSEF) <br>
                                            Teléfono: 01 800 999 8080 y 5553400999. Página de Internet. <a
                                                href="www.gob.mx/condusef ">www.gob.mx/condusef</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- CONTRACT BODY -->
                        <div class="text-justify">

                            <strong>CONTRATO DE COMISIÓN MERCANTIL</strong> (en lo sucesivo <u>“Contrato”</u>) que
                            celebran por una parte {{ userName }}, {{ personTypeText }}{{ userLegalRepresentativeName }} (en
                            lo
                            sucesivo <u>"Comitente"</u>), y
                            por la otra Red Girasol, S.A.P.I. de C.V., Institución de Financiamiento Colectivo,
                            representada en este acto por Jorge
                            Armando Cárdenas Tijerina (en lo sucesivo "Comisionista" y en conjunto con el Comitente como
                            las <u>“Partes”</u> o
                            individualmente cada uno como una <u>“Parte”</u>); mismo que sujetan al tenor de las
                            siguientes Declaraciones y Cláusulas.
                            <br>
                            <div style="text-align: center"><strong>DECLARACIONES</strong></div>
                            <br>

                            <ol class="text-justify" style="font-weight:bold">
                                <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Declara el Comisionista a través de su representante legal que: </span><br> <br>
                                    <ol class="text-justify" style="font-weight:bold">
                                        <li style="font-size:14px!important">
                                            <span style="font-weight:normal">
                                                Es una sociedad constituida de conformidad con las leyes de los Estados
                                                Unidos Mexicanos (<u>“México”</u>)
                                                según consta en la escritura pública número 49,903 de fecha 14 de abril
                                                de 2016 otorgada ante la fe del
                                                licenciado Eduardo Adolfo Manautou Ayala, titular de la Notaría Pública
                                                No. 123 con ejercicio en
                                                Monterrey, Nuevo León y cuyo primer testimonio quedó inscrito en el
                                                registro público de la ciudad de
                                                Monterrey, Nuevo León bajo el folio mercantil electrónico número
                                                160394*1. Asimismo, declara que
                                                realiza operaciones de financiamiento colectivo en términos de la Ley
                                                FinTech, y que cuenta con los
                                                recursos y medios necesarios para mantener dicha autorización.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Su representante cuenta con las facultades necesarias y suficientes para
                                                suscribir el presente Contrato,
                                                mismas que se encuentran vigentes y no le han sido revocadas o limitadas
                                                en forma alguna a la fecha de
                                                celebración del presente contrato.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Su objeto social consiste en, entre otros, recibir y publicar las
                                                solicitudes de operaciones de
                                                financiamiento colectivo de deuda de conformidad con el artículo 16
                                                fracción 1 de la Ley FinTech
                                                (“Operaciones”) de los Solicitantes y sus Proyectos a través del Sitio o
                                                medio de comunicación
                                                electrónica o digital que utilice para realizar sus actividades.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Que: (i) se abstendrá de privilegiar los intereses de algún Cliente
                                                sobre otro o actuar con conflictos de
                                                interés en la ejecución de estos; (ii) mantendrá informados a los
                                                Clientes de los actos realizados en la
                                                ejecución de los mandatos o comisiones de que se trate; y (iii) dará a
                                                conocer de forma clara las
                                                contraprestaciones que, en su caso, se cobrarían por la ejecución del
                                                mandato. Para los efectos de esta
                                                declaración, se entenderá por Cliente tanto a los Inversionistas como a
                                                los Solicitantes en los términos del
                                                artículo 16 de la Ley FinTech.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Que para organizarse y operar como institución de financiamiento
                                                colectivo (“IFC”) obtuvo una
                                                autorización otorgada por la CNBV, previo acuerdo del Comité
                                                Interinstitucional, en términos del Capítulo
                                                I del Título III de la Ley FinTech. Las IFC, además de cumplir con las
                                                obligaciones establecidas en la Ley
                                                FinTech y en las disposiciones que de ella emanen, deberán tomar medidas
                                                para evitar que se difunda
                                                información falsa o engañosa a través de ellas. Adicionalmente, las ITF
                                                deberán difundir la información
                                                que permita a sus Clientes identificar los riesgos de las Operaciones
                                                que celebren con o a través de ellas,
                                                conforme a lo previsto en la Ley FinTech. <strong>Ni el Gobierno Federal
                                                    ni las entidades de la administración
                                                    pública paraestatal podrán responsabilizarse o garantizar los
                                                    recursos del Comitente que sean
                                                    utilizados en las Operaciones que celebren con las ITF o frente a
                                                    otros, así como tampoco asumir
                                                    alguna responsabilidad por las obligaciones contraídas por las ITF o
                                                    por el Comitente frente a otro,
                                                    en virtud de las Operaciones que celebren.</strong> Las ITF deberán
                                                señalar expresamente lo mencionado en este
                                                párrafo en sus respectivas páginas de internet, en los mensajes que
                                                muestren a través de las aplicaciones
                                                informáticas o transmitan por medios de comunicación electrónica o
                                                digital que utilicen para el ofrecimiento
                                                y realización de sus Operaciones, así como en la publicidad y los
                                                contratos que celebren con sus Clientes.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Que ha verificado los datos y autenticidad de los documentos obtenidos
                                                de manera digital por parte del Comitente.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Que su página de internet se identifica bajo el nombre de dominio: <a
                                                    href="https://www.redgirasol.com" target="_blank"
                                                    rel="noopener noreferrer">www.redgirasol.com </a> (el “Sitio”)
                                            </span>
                                        </li><br>
                                    </ol>
                                </li>
                                <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Declara el Comitente, por su propio derecho o por conducto de su representante
                                        declara que: </span><br> <br>
                                    <ol class="text-justify" style="font-weight:bold">
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Es una persona física mayor de edad o una sociedad debidamente
                                                constituida conforme a sus leyes
                                                aplicables.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Que su persona se encuentra en pleno uso y goce de sus facultades
                                                mentales, o, en su caso, su representante
                                                legal cuenta con las facultades necesarias para celebrar el presente
                                                contrato según poder que en este acto se
                                                tiene a la vista.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Que la información y documentación presentada al Comisionista es
                                                verídica, sabiendo de las consecuencias
                                                penales de presentar información falsa
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Es un usuario registrado del Sitio y reconoce y declara haber revisado,
                                                entendido y aceptado sin reserva
                                                alguna los términos y condiciones, avisos legales y cualesquiera otras
                                                cláusulas, declaraciones, derechos
                                                y/u obligaciones que contiene dicho Sitio.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Mantiene, a su nombre una cuenta bancaria que podrá ser revelada por el
                                                Comisionista a través del Sitio.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Que ha entregado datos y documentos veraces de manera digital al
                                                Comisionista.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Que reconoce que las Operaciones que realizará en el Sitio podrán ser
                                                solamente en pesos, moneda nacional
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Que no ha obtenido ni obtendrá, previo a la firma de los Documentos del
                                                Crédito, otro financiamiento para
                                                el Proyecto a través de otra institución de financiamiento colectivo, en
                                                términos de la Ley FinTech.
                                            </span>
                                        </li><br>
                                    </ol>
                                </li>
                                <li style="font-size:14px!important"><span style="font-weight:normal">
                                        Declaran las PARTES que :</span><br> <br>
                                    <ol class="text-justify" style="font-weight:bold">
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Entienden y conocen la terminología empleada en el presente Contrato, no
                                                obstante la utilización de
                                                tecnicismos propios de la materia que aquí se regula y que son empleados
                                                en la determinación de los
                                                derechos y obligaciones que aquí se pactan.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Se reconocen mutuamente la personalidad y, en su caso, representación
                                                con la cual cada una de las Partes
                                                comparece a la celebración del presente Contrato.</span>
                                        </li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Están de acuerdo en celebrar el presente Contrato, sujetándolo al tenor
                                                de las siguientes cláusulas,
                                                apéndices, anexos y convenios accesorios que eventualmente se llegaren a
                                                celebrar entre las Partes
                                                incluyendo aquellos que se celebren con o sin la intervención o
                                                comparecencia de terceros; testimonio de
                                                los cuales se pondrán a disposición del Comitente una vez sea celebrado
                                                el presente Contrato, en su cuenta
                                                en el Sitio y mediante correo electrónico enviado a la cuenta registrada
                                                por el Comitente en el Sitio.
                                            </span>
                                        </li><br>
                                    </ol>
                                </li>
                            </ol>

                            En virtud de lo anterior, las Partes, de común acuerdo, se sujetan a las siguientes:<br>

                            <br>
                            <div style="text-align: center"><strong>CLÁUSULAS</strong></div>
                            <br>

                            <ol class="text-justify" style="font-weight:bold">
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong><u>Objeto del Contrato.</u></strong>
                                        Por virtud del presente Contrato, el Comitente otorga y confiere una comisión
                                        mercantil al
                                        Comisionista para obtener el Monto especificado por el Comitente para el
                                        financiamiento del Proyecto por parte de los
                                        Inversionistas de manera no exclusiva dentro del Territorio, e incluyendo sin
                                        limitar la actividades listadas en la Cláusula 3
                                        del presente bajo la Plataforma del Comisionista. <br><br>

                                        Por este mismo medio, El Comisionista acepta expresamente la comisión mercantil
                                        objeto del presente Contrato,
                                        obligándose con el Comitente a desempeñarla de conformidad con las disposiciones
                                        de este Contrato, sin exceder de las
                                        facultades conferidas en el mismo y sus accesorios.
                                        <br><br>
                                        <ol class="text-justify" style="font-weight:bold">
                                            <li style="font-size:14px!important">
                                                <span style="font-weight:normal">
                                                    <strong>Derechos y obligaciones del Comisionista bajo el presente
                                                        Contrato.</strong>
                                                    A continuación, el Comisionista
                                                    encontrará un condensado de los derechos y obligaciones a su cargo
                                                    bajo el presente Contrato, en la inteligencia
                                                    que dichos derechos y obligaciones se encontrarán sujetos a los
                                                    términos y condiciones previstos en los numerales
                                                    correspondientes:
                                                </span> <br><br>
                                                <ol class="text-justify" style="font-weight:bold">
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Obligación de obtener el Monto necesario para el
                                                                Proyecto, en beneficio del Comitente</strong>,
                                                            en cumplimiento de lo establecido en las Cláusulas 1, 2, 3.1
                                                            y 3.2. de este Contrato.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Obligación de celebrar el Crédito,</strong>
                                                            mediante la celebración de los actos necesarios incluyendo
                                                            los
                                                            Documentos del Crédito conforme a lo previsto en las
                                                            Cláusulas 3.3 y 3.4 (incluyendo, sin limitación, las
                                                            Cláusulas 3.4.2, 3.4.3 y 3.4.4) del presente.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Obligación de poner a disposición del Comitente el
                                                                Comprobante de Operación, el Estado de
                                                                Cuenta y el Estado de Operación,</strong>
                                                            y avisar de sus movimientos inusuales y modificaciones,
                                                            conforme a
                                                            lo establecido en las Cláusulas 3.4.5 y 3.4.7 del presente.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Derecho a percibir las comisiones</strong> previstas
                                                            en la Cláusula 5 del presente.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Derecho a percibir los beneficios ambientales de los
                                                                Proyectos,</strong> en términos de la Cláusula 6.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Derecho a terminar este Contrato,</strong> en
                                                            seguimiento de lo establecido en la Cláusula 8.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Obligación de cumplir con los impuestos a su
                                                                cargo,</strong> según se describe en la Cláusula 12
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Obligación de ejecutar el plan de continuidad de
                                                                negocio en causa de contingencias
                                                                operativas,</strong> cuando se satisfagan las
                                                            condiciones previstas en la Cláusula 19 del presente.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Obligaciones y derechos diversos, </strong>en
                                                            términos de las Cláusulas 13 a 25 de este Contrato.
                                                        </span>
                                                    </li><br>
                                                </ol>
                                            </li><br>
                                            <li style="font-size:14px!important">
                                                <span style="font-weight:normal">
                                                    <strong>Derechos y obligaciones del Comitente bajo el presente
                                                        Contrato.</strong>
                                                    A continuación, el Comitente
                                                    encontrará un condensado de los derechos y obligaciones a su cargo
                                                    bajo el presente Contrato, en la inteligencia
                                                    que dichos derechos y obligaciones se encontrarán sujetos a los
                                                    términos y condiciones previstos en los numerales
                                                    correspondientes:

                                                </span> <br><br>
                                                <ol class="text-justify" style="font-weight:bold">
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Derecho a recibir el Monto solicitado para el
                                                                Proyecto</strong>,
                                                            en seguimiento de lo establecido en las
                                                            Cláusulas 2 y 3.4 de este Contrato, y en todo caso, mediante
                                                            la celebración de los Documentos del Crédito
                                                            en términos adicionales de lo previsto en la Cláusula 3.3
                                                            del presente.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Obligación de pagar las comisiones
                                                                aplicables,</strong>
                                                            en beneficio del Comisionista, de conformidad con la
                                                            Cláusula 5 del presente.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Obligación de ceder los beneficios ambientales de
                                                                los Proyectos,</strong> en favor del Comisionista, en
                                                            términos de la Cláusula 6.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Derecho a terminar este Contrato,</strong> en
                                                            seguimiento a lo establecido en la Cláusula 8.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Obligación de no competir con RedGirasol,
                                                            </strong>bajo los términos de la Cláusula 11.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Obligación de cumplir con los impuestos a su
                                                                cargo,</strong> según se describe en la Cláusula 12.

                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Derecho de acudir ante la UNE y la
                                                                CONDUSEF,</strong> para efectos de la prestación de
                                                            servicios
                                                            financieros por parte de RedGirasol, en apego a lo
                                                            establecido en la Cláusula 13 del presente Contrato.
                                                        </span>
                                                    </li><br>
                                                    <li style="font-size:14px!important">
                                                        <span style="font-weight:normal">
                                                            <strong>Obligaciones y derechos diversos,</strong> en
                                                            términos de la Cláusulas 13 a 25 de este Contrato.
                                                        </span>
                                                    </li><br>
                                                </ol>
                                            </li>
                                        </ol>
                                    </span>
                                </li> <br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Desempeño de la comisión.</u></strong>
                                        El Comisionista, para el desempeño de sus obligaciones, deberá obrar con buena
                                        fe y
                                        realizará sus mejores esfuerzos para el cumplimiento adecuado de la comisión
                                        aquí encomendada. El Comisionista deberá
                                        hacer su mejor esfuerzo por conocer en todo momento las mejores prácticas de
                                        promoción del Proyecto en el Sitio. El
                                        Comitente acepta y reconoce que el Comisionista no realizará ninguna práctica de
                                        promoción del Proyecto hasta en tanto el
                                        Comitente no realice el pago de la comisión referida en la Cláusula 5 en favor
                                        del Comisionista.<br><br>

                                        El Comitente reconoce que el hecho de solicitar el financiamiento del Proyecto
                                        por medio del Sitio no significa que el Monto
                                        será entregado inmediatamente, ya que la dispersión del Monto se encuentra
                                        sujeta a una condición suspensiva consistente
                                        en: (i) el fondeo total del Monto del Proyecto por los Inversionistas a través
                                        del Sitio y, en caso de obtenerlo, (ii) que el
                                        Comitente firme los Documentos del Crédito.
                                    </span>
                                </li> <br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Actividades que comprende la comisión.</u></strong>
                                        Durante la vigencia de este Contrato, el Comisionista estará facultado
                                        para desarrollar las siguientes actividades con el fin de promover los
                                        Proyectos con los Inversionistas y/o el Comitente:</span> <br> <br>
                                    <ol class="text-justify" style="font-weight:bold">
                                        <li style="font-size:14px!important">
                                            <span style="font-weight:normal">&nbsp;
                                                <strong>Promoción.</strong>
                                                El Comisionista estará facultado para solicitar, de la manera más amplia
                                                y a su discreción, a
                                                los Inversionistas el financiamiento para el financiamiento del Proyecto
                                                de una manera comercialmente
                                                razonable, pudiendo transmitir material promocional e información
                                                diversa a los Inversionistas según sea
                                                necesario para cumplir el objeto de este Contrato. La publicidad y
                                                promoción podrá ser efectuada
                                                mediante todos aquellos medios que el Comisionista considere adecuados
                                                para el correcto desarrollo de
                                                las actividades de promoción. n. El Comitente instuye, acuerda y acepta
                                                que, en caso de que el Inversionista sea
                                                una Entidad Financiera, el Comisionista podrá compartir la información
                                                necesaria para que dicha Entidad
                                                Financiera cumpla con sus obligaciones regulatorias.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important">
                                            <span style="font-weight:normal">&nbsp;
                                                <strong>Entrega de Información.</strong>
                                                El Comisionista estará facultado para entregar toda información a los
                                                Inversionistas relacionada al Proyecto del Comitente.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important">
                                            <span style="font-weight:normal">&nbsp;
                                                <strong>Medio de venta.</strong>
                                                El Comisionista a través de su Sitio celebrará los siguientes actos
                                                jurídicos para la
                                                formalización del Proyecto, sin limitar a: (i) este contrato de comisión
                                                mercantil y (ii) los Documentos del
                                                Crédito, (iii) un contrato de comisión mercantil del Comisionista con
                                                los Inversionistas.
                                            </span>
                                        </li><br>
                                        <li style="font-size:14px!important">
                                            <span style="font-weight:normal">&nbsp;
                                                <strong>Otorgamiento del Crédito.</strong>
                                                El Comisionista deberá llevar a cabo las siguientes actividades
                                                relacionadas a los Servicios:</span><br><br>
                                            <ol class="text-justify" style="font-weight:bold">
                                                <li style="font-size:14px!important">
                                                    <span style="font-weight:normal">&nbsp;
                                                        <strong>Búsqueda de Inversionistas.</strong>
                                                        El Comisionista deberá buscar e identificar Inversionistas para
                                                        financiar el Proyecto del Comitente, durante el plazo de [*],
                                                        mismo que podrá extenderse a
                                                        discreción del Comisionista. Posterior a la conclusión de dicho
                                                        plazo de búsqueda e identificación
                                                        de Inversionistas para el financiamiento del Proyecto del
                                                        Comitente, el Comisionista pondrá a
                                                        disposición del Comitente el Monto solicitado se dispondrán de
                                                        conformidad con los términos
                                                        establecidos en los Documentos del Crédito.
                                                    </span>
                                                </li><br>
                                                <li style="font-size:14px!important">
                                                    <span style="font-weight:normal">&nbsp;
                                                        <strong>Análisis Financiero.</strong>
                                                        El Comisionista estará facultado para realizarle al Comitente un
                                                        análisis de
                                                        crédito y riesgo respecto de las solicitudes de crédito
                                                        presentadas por el Comitente. Para ello, el
                                                        Comisionista solicitará del Comitente diversa información
                                                        permitida bajo la ley aplicable. El
                                                        Comitente podrá verificar por si mismo o a través de terceros la
                                                        veracidad de la documentación,
                                                        tomando en consideración además la declaración 2.9 realizada por
                                                        el Comisionista. Por otro lado,
                                                        la información será analizada de conformidad con lo establecido
                                                        en los Términos y Condiciones
                                                        del Sitio. Dicha información será publicada por el Comisionista
                                                        en su Sitio para referencia de los
                                                        Inversionistas de conformidad con los Términos y Condiciones y
                                                        el Aviso de Privacidad publicado
                                                        en el Sitio.
                                                    </span>
                                                </li><br>
                                                <li style="font-size:14px!important">
                                                    <span style="font-weight:normal">&nbsp;
                                                        <strong>Registro de garantía.</strong>
                                                        El Comisionista podrá dar de alta la garantía prevista en los
                                                        Documentos del
                                                        Crédito en el portal que la Secretaría de Economía establece
                                                        para el registro de garantías mobiliarias
                                                        (RUG) o en el Registro Público de Propiedad correspondiente.
                                                    </span>
                                                </li><br>
                                                <li style="font-size:14px!important">
                                                    <span style="font-weight:normal">&nbsp;
                                                        <strong>Actividades frente al proveedor.</strong>
                                                        En caso de financiamiento de Productos, (i) celebrar un
                                                        instrumento jurídico mediante el cual el Comisionista obligue
                                                        jurídicamente al proveedor
                                                        seleccionado a realizar la instalación de los Productos una vez
                                                        se encuentre realizado el fondeo del
                                                        Proyecto; y (ii) a realizar el pago de los recursos
                                                        efectivamente fondeados, incluyendo el pago
                                                        expedido por el proveedor en nombre y representación del
                                                        Comitente en favor del proveedor para
                                                        los Productos una vez que: (a) el Comitente haya firmado los
                                                        Documentos del Crédito y (b) el
                                                        Comitente instruya dicho efecto al Comisionista.
                                                    </span>
                                                </li><br>
                                                <li style="font-size:14px!important">
                                                    <span style="font-weight:normal">
                                                        <strong>Depósito. </strong>
                                                        El Comisionista solo podrá recibir recursos del Comitente que
                                                        provengan directamente
                                                        de cuentas de depósito de dinero abiertas en una entidad
                                                        financiera autorizada para recibir dichos
                                                        depósitos conforme a la normatividad que le resulte aplicable.
                                                        El Comitente podrá realizar, a
                                                        nombre y cuenta del Comisionista, pagos de seguros necesarios
                                                        para la protección de los activos
                                                        listados en los Documentos del Crédito. El Comitente, de acuerdo
                                                        a los Documentos del Crédito,
                                                        podrá recibir pagos de terceros para garantizar el pago del
                                                        Crédito, en los términos establecidos en
                                                        dichos Documentos del Crédito.
                                                    </span>
                                                </li><br>
                                                <li style="font-size:14px!important">
                                                    <span style="font-weight:normal">
                                                        <strong>Comprobante de Operación.</strong>
                                                        El Comisionista deberá poner a disposición del Comitente
                                                        comprobantes de operación a través de los Medios Oficiales con
                                                        las siguientes características: (i)
                                                        denominación social y logotipo de RedGirasol; (ii) medio de
                                                        disposición, (iii) el folio de la
                                                        operación, (iv) monto, fecha y hora, (v) tipo de operación, (vi)
                                                        datos de identificación de la cuenta
                                                        y (vii) concepto y monto de las comisiones cobradas.
                                                    </span>
                                                </li><br>
                                                <li style="font-size:14px!important">
                                                    <span style="font-weight:normal">
                                                        <strong>Disposiciones CONDUSEF.</strong>
                                                        De conformidad con el artículo 16 de las Disposiciones de
                                                        carácter
                                                        general de la CONDUSEF en materia de transparencia y sanas
                                                        prácticas aplicables a las
                                                        instituciones de tecnología financiera, las Partes acuerdan la
                                                        siguiente información: <br><br>
                                                        I. El(los) nombre(s) del (los) obligado(s) solidario(s): [∙]. El
                                                        nombre del obligado solidario podrá
                                                        ser modificado por las Partes mediante acuerdo por escrito en
                                                        los Documentos del Crédito. <br><br>
                                                        II. La fecha de corte para el cálculo de intereses y la forma en
                                                        que se entregará el crédito podrá ser
                                                        revisado por el Comitente en los Documentos del Crédito.
                                                        <br><br>
                                                        III. Tasa de interés fija: [∙].
                                                        IV. La metodología de cálculo de los intereses ordinarios y
                                                        moratorios podrá ser revisado por el
                                                        Comitente en los Documentos del Crédito. <br><br>
                                                        V. La periodicidad y fecha límite de pago o el documento ser
                                                        revisado por el Comitente en los
                                                        Documentos del Crédito. <br><br>
                                                        VI. Los términos y condiciones para pagos anticipados podrá ser
                                                        revisado por el Comitente en los
                                                        Documentos del Crédito y adelantados; <br><br>
                                                        VII. Los medios de pago permitidos y las fechas de acreditación
                                                        de los pagos podra ser revisado
                                                        por el Comitente en los Documentos del Crédito.; <br><br>
                                                        Para efectos de claridad, toda la información podrá ser revisada
                                                        en los Documentos del Crédito, en
                                                        los estados de cuenta y en el Sitio.

                                                    </span>
                                                </li><br>
                                                <li style="font-size:14px!important">
                                                    <span style="font-weight:normal">
                                                        <strong>Estados de cuenta.</strong>
                                                        El Comisionista deberá poner a disposición del Comitente un
                                                        estado
                                                        de cuenta a través del Sitio con las siguientes características:
                                                        (i) denominación social, domicilio,
                                                        número de teléfono y logotipo y Sitio de RedGirasol; (ii) nombre
                                                        del usuario, número de cuenta,
                                                        (iii) periodo que corresponda, (iv) saldo inicial y final de
                                                        cada proyecto invertido, (v) movimientos
                                                        de cargo o abono (incluyendo descripción), (vi) un desglosa de
                                                        las comisiones cobradas y sus
                                                        detalles, (viii) los cargos objetados, (ix) el aviso de la UNE y
                                                        que, en caso de no tener respuesta de
                                                        la UNE, la notificación de que puede acudir ante la CONDUSEF,
                                                        (x) impuestos retenidos y (xi)
                                                        leyendas legales. Además, deberá contener lo siguiente (i) : el
                                                        Monto del crédito, el saldo inicial y
                                                        el de la fecha de corte o del final del periodo; (ii) el monto a
                                                        pagar en el periodo, en su caso,
                                                        desglosado en capital, intereses y cualquier otro cargo, así
                                                        como el saldo insoluto de principal;(iii)
                                                        tratándose de pagos anticipados parciales, la correspondiente
                                                        reducción del monto de los pagos o
                                                        del número de pagos pendientes, según sea el caso; (iv) el monto
                                                        base sobre el cual fue calculado
                                                        el interés ordinario y moratorio en su caso, en términos de lo
                                                        previsto en el Contrato; (v) en su caso,
                                                        la fecha límite de pago, señalando que cuando dicha fecha
                                                        corresponda a un día inhábil bancario,
                                                        el pago podrá realizarse sin cargo adicional el siguiente día
                                                        hábil bancario; (vi) la fecha de corte, y
                                                        (vii) un recuadro, que indique lo siguiente: (a) CAT calculado
                                                        de conformidad a las disposiciones
                                                        emitidas por Banco de México; (b) tasa de interés ordinaria y
                                                        moratoria, expresada en términos
                                                        anuales simples y en porcentajes, e (c) intereses cargados en el
                                                        periodo correspondiente.
                                                        <br><br>
                                                        Para efectos de claridad, en caso de actualizarse alguno de los
                                                        datos que contenga el estado de
                                                        cuenta del Comitente, o bien, se realicen movimientos inusuales
                                                        en su respectiva cuenta en el Sitio,
                                                        el Comisionista deberá comunicar la existencia de dichas
                                                        circunstancias al Comitente mediante los
                                                        Medios Oficiales.
                                                    </span>
                                                </li><br>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Actividades excluidas de la comisión.</u></strong>
                                        Se encuentra expresamente excluido dentro de las actividades
                                        comprendidas en la comisión aquí conferida la elección por parte del
                                        Comisionista de empezar con el financiamiento del
                                        Proyecto por parte del Comitente, siendo que dicha elección la realiza el
                                        Comitente al firmar este Contrato y realizar los
                                        pagos establecidos en este Contrato, incluyendo pero sin limitar las
                                        establecidas en la Cláusula 5 del presente Contrato,
                                        además de otras excepciones previstas en este Contrato.
                                    </span>
                                </li> <br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Comisión.</u></strong>
                                        Las Partes acuerdan que el Comisionista percibirá, si el Comitente decide abrir
                                        la cuenta en el Sitio para
                                        que se financie un Proyecto por el Comitente, corresponderá al Comisionista el
                                        2% (dos por ciento) del valor del Monto
                                        más el correspondiente Impuesto al Valor Agregado como comisión por gastos de
                                        pre-originación. Esta comisión será
                                        cobrada al Comitente,a discreción del Comisionista, por alguna de las siguientes
                                        opciones: (i) por transferencia electrónica
                                        al momento de abrir la cuenta en el Sitio y a la firma del presente Contrato o
                                        (ii) mediante descuento en el Monto una vez
                                        que el Crédito haya sido fondeado. En caso de que el Comitente opte (i) por
                                        detener el fondeo de su Crédito colocado en el
                                        Sitio o (ii) por no proceder a la firma de los Documentos del Crédito, esta
                                        comisión no será reembolsada y será considerada
                                        como una pena convencional.
                                    </span>
                                </li> <br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Cesión de beneficios ambientales.</u></strong>
                                        Las Partes acuerdan que el Comitente cede gratuitamente al Comisionista los
                                        beneficios ambientales que pudiesen generar los Proyectos.
                                    </span>
                                </li> <br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Vigencia.</u></strong>
                                        El presente Contrato empezará a surtir sus efectos a partir de la fecha de su
                                        firma. El Contrato estará
                                        vigente hasta en tanto el Crédito sea pagado en su totalidad por el Comitente.
                                    </span>
                                </li> <br>
                                <li style="font-size:14px!important"><u>Rescisión.</u><span
                                        style="font-weight:normal">&nbsp;
                                        Las Partes podrán rescindir el presente contrato sin previa declaración
                                        judicial, por falta de
                                        cumplimiento de su contraparte de cualquiera de sus obligaciones derivadas del
                                        mismo y en general por los casos de
                                        incumplimiento que al afecto señalen las disposiciones legales correspondientes,
                                        para lo cual bastará que la Parte afectada
                                        notifique por escrito a la otra con 10 (diez) días naturales de anticipación a
                                        la fecha en que deba de surtir efectos la misma.
                                        La Parte que se encuentra en incumplimiento tendrá 30 (treinta) días naturales
                                        para solventar la acción que originó el
                                        incumplimiento a partir de la notificación de incumplimiento de la contraparte.
                                        Además, las Partes convienen en forma
                                        expresa que las Partes podrán rescindir el presente contrato, sin necesidad de
                                        declaración judicial, en caso de que se
                                        presente alguna de las siguientes circunstancias:</span> <br> <br>
                                    <ol class="text-justify" style="font-weight:bold">
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Han proporcionado datos falsos al registrarse o al formular el Contrato,
                                                o si durante su vigencia y con
                                                motivo de las obligaciones derivadas del mismo, proporcionare
                                                información falsa, errónea, insuficiente, o
                                                si la información solicitada no se proporcionare o se hiciere en forma
                                                extemporánea para la evaluación para
                                                realizar el análisis financiero establecido en el inciso 3.4.2 de este
                                                Contrato.
                                            </span></li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Si se declaran en disolución, liquidación, quiebra o suspensión de
                                                pagos.
                                            </span></li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Si son emplazados a huelga, o existe amenaza de que se inicie el
                                                procedimiento correspondiente.
                                            </span></li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Si incumplen, cumplen en forma parcial, suspenden, o de cualquier forma
                                                prestan las actividades aquí
                                                establecidas de manera irregular, deficiente, negligente o
                                                irresponsable; o bien por violar o incumplir
                                                cualquiera de las obligaciones a su cargo, con motivo o derivadas del
                                                presente contrato.
                                            </span></li><br>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Si el Comitente no firma los Documentos del Crédito dentro de un plazo
                                                de 10 días hábiles a partir de que
                                                el Comisionista obtenga el Monto por parte de los Inversionistas.
                                            </span></li><br>
                                    </ol>
                                </li>

                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Efectos de la Terminación.</u></strong>
                                        Con excepción de lo establecido en las cláusulas 7 y 8, una vez terminado el
                                        presente
                                        Contrato, los derechos y obligaciones de las Partes terminarán, salvo por
                                        aquellas que por su naturaleza deban sobrevivir a
                                        la terminación de este Contrato.
                                    </span>
                                </li> <br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><span><u>Relación entre las Partes.</u></span></strong>
                                        Queda expresamente convenido que de ninguna manera el presente contrato deberá o
                                        podrá ser considerado o interpretado para crear una coinversión, sociedad,
                                        representación, asociación, asociación en
                                        participación o relación de agencia entre las Partes para ningún propósito.
                                    </span>
                                </li> <br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>No competencia.</u></strong>
                                        Desde la firma del presente Contrato, durante su vigencia y hasta 3 (tres) años
                                        después de la
                                        terminación del mismo, el Comitente no podrá, por su propia cuenta, en nombre o
                                        por cuenta de, o en relación con alguna
                                        Persona, directa o indirectamente, bajo cualquier carácter, incluyendo pero no
                                        limitado a, empleador, empleado, funcionario,
                                        consejero, gerente, principal, agente, representante, parte en un contrato de
                                        coinversión, socio, accionista o cualquier otro
                                        titular de participaciones, contratista independiente, licenciante,
                                        licenciatario, franquiciador, franquiciado, distribuidor,
                                        consultor, asesor, promotor, proveedor, fideicomisario o mediante y a través de
                                        cualquier corporación, sociedad cooperativa,
                                        sociedad, empresa de responsabilidad limitada, fideicomiso, asociación irregular
                                        o de otra manera, continuar con,
                                        comprometerse a, tener interés financiero alguno o de otra índole en, o de
                                        alguna otra manera estar involucrado
                                        comercialmente en cualesquiera sociedades, actividades o negocios en todo o
                                        alguna parte del Territorio que sean
                                        substancialmente similares o iguales o que estén en competencia con RedGirasol.
                                    </span> <br><br>
                                    <ol class="text-justify" style="font-weight:bold">
                                        <li class="dosdigitos" style="font-size:14px!important"><span
                                                style="font-weight:normal">
                                                Desde la firma del presente Contrato, durante su vigencia y hasta 3
                                                (tres) años después de la terminación del mismo,
                                                el Comitente no podrá, por su propia cuenta, a nombre de, o en relación
                                                con alguna Persona, directa o indirectamente, bajo
                                                cualquier carácter, incluyendo pero no limitado a, como empleador,
                                                empleado, funcionario, consejero, gerente, principal,
                                                agente, representante, parte en un contrato de coinversión, socio,
                                                accionista u otro titular de participaciones, contratista
                                                independiente, licenciante, licenciatario, franquiciador, franquiciado,
                                                distribuidor, consultor, asesor, promotor, proveedor o
                                                fideicomisario o mediante y a través de cualquier corporación, sociedad
                                                cooperativa, sociedad, empresa de responsabilidad
                                                limitada, fideicomiso, asociación irregular o de otra manera:
                                            </span><br><br>
                                            <ol class="text-justify" style="font-weight:bold">
                                                <li class="tercernivel" style="font-size:14px!important"><span
                                                        style="font-weight:normal">
                                                        Contactar a o captar el negocio de (o procurar o asistir en el
                                                        contacto o captación del negocio de) cualquier
                                                        Inversionista o proveedor para bienes y servicios similares o
                                                        iguales a aquellos previamente ofrecidos por
                                                        RedGirasol;
                                                    </span></li><br>
                                                <li class="tercernivel" style="font-size:14px!important"><span
                                                        style="font-weight:normal">
                                                        Aceptar (o procurar o asistir en la aceptación de) cualquier
                                                        negocio de cualquier Inversionista o proveedor
                                                        para bienes y servicios similares o iguales a aquellos ofrecidos
                                                        en cualquier momento por RedGirasol; o
                                                    </span></li><br>
                                                <li class="tercernivel" style="font-size:14px!important"><span
                                                        style="font-weight:normal">
                                                        Suministrar (o procurar o asistir en el suministro de) a
                                                        cualquier Inversionista o proveedor cualesquier
                                                        bienes y servicios similares o iguales a aquellos previamente
                                                        ofrecidos por RedGirasol.
                                                    </span></li><br>
                                            </ol>
                                        </li>
                                        <li class="dosdigitos" style="font-size:14px!important"><span
                                                style="font-weight:normal">
                                                Desde la firma del presente Contrato, durante su vigencia y hasta 3
                                                (tres) años después de la terminación del mismo,
                                                el Comitente no podrá, por su propia cuenta, a nombre de, o en relación
                                                con alguna Persona, directa o indirectamente, bajo
                                                cualquier carácter, incluyendo pero no limitado a, como empleador,
                                                empleado, funcionario, consejero, gerente, principal,
                                                agente, representante, parte en un contrato de coinversión, socio,
                                                accionista u otro titular de participaciones, contratista
                                                independiente, licenciante, licenciatario, franquiciador, franquiciado,
                                                distribuidor, consultor, asesor, promotor, proveedor o
                                                fideicomisario o mediante y a través de cualquier corporación, sociedad
                                                cooperativa, sociedad, empresa de responsabilidad
                                                limitada, fideicomiso, asociación irregular o de otra manera:
                                            </span><br><br>
                                            <ol class="text-justify" style="font-weight:bold">
                                                <li class="tercernivel" style="font-size:14px!important"><span
                                                        style="font-weight:normal">
                                                        Emplear, ofrecer empleo a, o solicitar el empleo o compromiso
                                                        de, o de otra
                                                        manera alentar a cualquier individuo que sea empleado por
                                                        RedGirasol a terminar su relación laboral con
                                                        los mismos, sin importar si dicho individuo pudiere incurrir en
                                                        algún incumplimiento de su contrato o los
                                                        términos del empleo al terminar el empleo con RedGirasol; o
                                                    </span></li><br>
                                                <li class="tercernivel" style="font-size:14px!important"><span
                                                        style="font-weight:normal">
                                                        Procurar o asistir a cualquier Persona para emplear, ofrecer
                                                        empleo a o solicitar
                                                        el empleo o compromiso de, o de otra manera alentar a cualquier
                                                        dicho individuo a terminar su relación
                                                        laboral con RedGirasol.
                                                    </span></li><br>
                                            </ol>
                                        </li>
                                        <li style="font-size:14px!important"><span style="font-weight:normal">
                                                Desde la firma del presente Contrato, durante su vigencia y hasta 3
                                                (tres) años después de la terminación del mismo,
                                                el Comitente no podrá, por su propia cuenta, a nombre de, o en relación
                                                con alguna Persona, directa o indirectamente, bajo
                                                cualquier carácter, incluyendo pero no limitado a, como empleador,
                                                empleado, funcionario, consejero, gerente, principal,
                                                agente, representante, parte en un contrato de coinversión, socio,
                                                accionista u otro titular de participaciones, contratista
                                                independiente, licenciante, licenciatario, franquiciador, franquiciado,
                                                distribuidor, consultor, asesor, promotor, proveedor o
                                                fideicomisario o mediante y a través de cualquier corporación, sociedad
                                                cooperativa, sociedad, empresa de responsabilidad
                                                limitada, fideicomiso, asociación irregular o de otra manera, interferir
                                                o intentar interferir con RedGirasol, como sociedad
                                                y en sus operaciones, o persuadir o intentar persuadir a cualquier
                                                Inversionista, proveedor o empleado de RedGirasol para
                                                descontinuar o alterar la relación de dicha Persona con RedGirasol.
                                            </span></li><br>
                                    </ol>
                                </li>

                                <li class="dosdigitos" style="font-size:14px!important"><u>Impuestos.</u><span
                                        style="font-weight:normal">&nbsp;
                                        Todos y cada uno de los impuestos, contribuciones, derechos y obligaciones que
                                        deban pagarse con
                                        motivo de las operaciones derivadas de las obligaciones incluidas en el presente
                                        Contrato, correrán por cuenta de quien para
                                        tales efectos sea señalado como causante por las leyes fiscales aplicables.
                                    </span></li> <br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Avisos y
                                                notificaciones.</u></strong>
                                        Todos los avisos, notificaciones, demandas y comunicaciones requeridas conforme
                                        a este
                                        Contrato deberán ser enviados por correo electrónico y por escrito y se
                                        considerará que se notificó debidamente si se entrega
                                        personalmente o se envía por correo certificado, correo registrado con el
                                        importe pre-pagado. Las Partes señalan como sus
                                        domicilios y direcciones convencionales para efectos de oír y recibir
                                        notificaciones, los siguientes:</span><br><br>

                                    Al Comisionista: <br><br>
                                    <span style="font-weight:normal">
                                        Red Girasol, S.A.P.I. de C.V.<br>
                                        Domicilio: Eugenio Garza Sada 3820 Int. 801, Colonia Mas Palomas, <br>
                                        Monterrey, Nuevo León México, C.P. 64780 <br>
                                        Tel: (81) 1107 0504<br>
                                        Correo: contacto@redgirasol.com<br>
                                        Atención: Mauricio de Mucha Treviño<br><br>
                                    </span>

                                    Al Comitente: <br><br>
                                    <span style="font-weight:normal">
                                        Nombre: {{ userName }}{{ userLegalRepresentativeName }} <br>
                                        Tel: {{ userPhone }}<br>
                                        Correo: {{ userEmail }}<br>
                                        Atención: {{ userName }}{{ userLegalRepresentativeName }}<br><br>

                                        Cualquier cambio o modificación de los domicilios aquí convenidos, deberá ser
                                        debidamente notificado por correo
                                        electrónico con acuse de recibo a la otra Parte para que surta efectos con por
                                        lo menos 10 (diez) días previos a la fecha en
                                        que pretenda la Parte notificante hacer cumplir dicho cambio de domicilio. De no
                                        cumplirse dicho requisito, la notificación
                                        hecha al último domicilio conocido surtirá efectos plenos.
                                        <br>
                                        <br>
                                        Cualquiera de las Partes de este Contrato podrá designar un nuevo domicilio u
                                        otro domicilio para notificaciones posteriores
                                        mediante notificación dada por escrito y observando lo estipulado en los
                                        párrafos anteriores.
                                        <br>
                                        <br>

                                        RedGirasol tiene a disposición del Comitente una Unidad Especializada de
                                        Atención a Usuarios (UNE) con domicilio de su
                                        titular en: Ave. Eugenio Garza Sada 3820 interior 801 Colonia Mas Palomas,
                                        Monterrey, Nuevo León C.P. 64875. Teléfono
                                        de contacto UNE 8111070504 con horario de lunes a viernes de 9:00 a 18:00 horas
                                        (horario de la Ciudad de México). Correo
                                        electrónico UNE: <a href="mailto:une@redgirasol.com">une@redgirasol.com</a> El
                                        Comitente podrá encontrar en el domicilio de la UNE carteles con los montos,
                                        conceptos y periodicidad de las comisiones vigentes.
                                        <br><br>
                                        Los datos del centro de atención telefónica de la CONDUSEF son los siguientes en
                                        la Ciudad de México 53400999 y del
                                        interior de la República 018009998080, la página de internet
                                        www.condusef.gob.mx, correo electrónico:
                                        <a href="mailto:asesoria@condusef.gob.mx">asesoria@condusef.gob.mx</a>.
                                        <br><br>
                                        RedGirasol pone a disposición del Comitente su página de internet
                                        www.redgirasol.com, las cuentas de sus redes sociales,
                                        su programa de educación financiera e información gratuita para evitar posibles
                                        riesgos tecnológicos, cibernéticos y de
                                        fraude, mismos que se podrán enviar al correo electrónico registrado por el
                                        Comitente en el Sitio.
                                    </span>
                                </li> <br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Cesión de Derechos.</u></strong>
                                        El Comitente no podrá ceder ni transferir los derechos y obligaciones adquiridos
                                        por virtud
                                        del presente Contrato, sin el consentimiento previo y por escrito del
                                        Comisionista. En caso contrario, aquella cesión o
                                        transferencia de derechos y/u obligaciones sin el consentimiento de la otra
                                        Parte será nula. </span>
                                </li> <br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Información
                                                Confidencial.</u></strong>
                                        Las Partes convienen en que cualquier información revelada o proporcionada por
                                        las
                                        Partes (cualquiera de sus filiales, sociedad controladora o subsidiarias), así
                                        como toda la información generada con relación
                                        al cumplimiento del objeto del presente Contrato, ya sea de manera escrita,
                                        verbal, electrónica, magnética o cualquier otra
                                        tecnología y demás información confidencial, o de cualquier otra índole, será
                                        considerada propiedad de dicha Parte.
                                        <br><br>
                                        Las Partes no revelarán a terceras Personas ninguna información propiedad de la
                                        otra Parte, debiendo utilizarla
                                        exclusivamente para el desarrollo del objeto de este instrumento. Una vez
                                        terminado este Contrato, las Partes deberán
                                        entregar en forma inmediata a la otra Parte toda la información y materiales que
                                        le hayan sido proporcionados.
                                    </span>
                                </li><br> <br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong>
                                            <u>Acuerdo Único y
                                                modificaciones.</u>
                                        </strong>
                                        Lo contenido en este Contrato constituye el acuerdo total entre las Partes
                                        respecto
                                        al objeto de este Contrato. Cualquier modificación a los términos y condiciones
                                        del presente Contrato, RedGirasol le
                                        notificará mediante los Medios Oficiales, con 30 (treinta) días naturales de
                                        anticipación a la fecha que entren en vigor dichos
                                        <br><br>
                                        cambios, entendiéndose que el Comitente deberá otorgar su consentimiento expreso
                                        conforme a lo previsto en la Cláusula
                                        22 de este Contrato dentro de dicho plazo, de lo contrario, deberá dar su
                                        consentimiento, en términos de la Cláusula 22 para
                                        dar por terminado el presente Contrato.
                                        <br><br>
                                        Los avisos de modificaciones al Contrato contendrán, por lo menos: I.
                                        Denominación social y logotipo de RedGirasol; II.
                                        Domicilio y teléfono de contacto de RedGirasol, así como domicilio, teléfono y
                                        correo electrónico de la Unidad
                                        Especializada de Atención de Usuarios (“UNE”); III. Resumen de todas las
                                        modificaciones realizadas; IV. Fecha a partir de
                                        la cual entran en vigor, y V. Su derecho para dar por terminado el Contrato.
                                        Usted puede solicitar la terminación del Contrato
                                        dentro de los 30 (treinta) días posteriores al aviso sin responsabilidad alguna
                                        a su cargo, y bajo las condiciones pactadas
                                        originalmente, sin que RedGirasol pueda cobrarle penalización alguna por dicha
                                        causa ni cobrar cantidad adicional alguna
                                        con excepción de los adeudos que ya se hubiera generado a la fecha en que el
                                        Comitente solicite dar por terminado el
                                        Contrato. En el evento de que El Comitente no esté de acuerdo con las
                                        modificaciones realizadas, podrá solicitar la
                                        terminación del contrato dentro de los 30 (treinta) días posteriores al aviso
                                        señalado en el párrafo anterior, sin
                                        responsabilidad alguna a su cargo, y bajo las condiciones anteriores a la
                                        modificación, siempre y cuando el Comitente no
                                        tenga dinero en inversiones de Créditos vigentes.
                                    </span>
                                </li><br>
                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong>
                                            <u>Aclaraciones</u>
                                        </strong>
                                        Cuando el Comitente no esté de acuerdo con alguno de los movimientos que
                                        aparezcan en el estado
                                        de cuenta respectivo o en los medios electrónicos, ópticos o de cualquier otra
                                        tecnología que se hubieren pactado, podrá
                                        presentar una solicitud de aclaración dentro del plazo de noventa días naturales
                                        contados a partir de la fecha de corte o, en
                                        su caso, de la realización de la operación o del servicio.
                                        <br><br>
                                        La solicitud respectiva podrá presentarse en la UNE de RedGirasol, mediante
                                        escrito, correo electrónico o cualquier otro
                                        medio por el que se pueda comprobar fehacientemente su recepción. En todos los
                                        casos, RedGirasol estará obligada a acusar
                                        recibo de dicha solicitud.
                                        <br><br>
                                        Tratándose de cantidades a cargo del Comitente dispuestas mediante cualquier
                                        mecanismo determinado al efecto por la
                                        Comisión Nacional para la Protección y Defensa de los Usuarios de los Servicios
                                        Financieros en disposiciones de carácter
                                        general, el Comitente tendrá el derecho de no realizar el pago cuya aclaración
                                        solicita, así como el de cualquier otra cantidad
                                        relacionada con dicho pago, hasta en tanto se resuelva la aclaración conforme al
                                        procedimiento a que se refiere esta Cláusula.
                                        Una vez recibida la solicitud de aclaración, RedGirasol tendrá un plazo máximo
                                        de cuarenta y cinco días para entregar al
                                        Comitente el dictamen correspondiente, anexando copia simple del documento o
                                        evidencia considerada para la emisión de
                                        dicho dictamen, con base en la información que, conforme a las disposiciones
                                        aplicables, deba obrar en su poder, así como
                                        un informe detallado en el que se respondan todos los hechos contenidos en la
                                        solicitud presentada por el Comitente.
                                        El dictamen e informe antes referidos deberán formularse por escrito y
                                        suscribirse por personal de RedGirasol facultado
                                        para ello. En el evento de que, conforme al dictamen que emita RedGirasol,
                                        resulte procedente el cobro del monto respectivo,
                                        el Comitente deberá hacer el pago de la cantidad a su cargo, incluyendo los
                                        intereses ordinarios conforme a lo pactado, sin
                                        que proceda el cobro de intereses moratorios y otros accesorios generados por la
                                        suspensión del pago realizada en términos
                                        de esta disposición;
                                        <br><br>
                                        Dentro del plazo de cuarenta y cinco días naturales contado a partir de la
                                        entrega del dictamen a que se refiere el párrafo
                                        anterior, RedGirasol estará obligada a poner a disposición del Comitente en la
                                        UNE de RedGirasol, el expediente generado
                                        con motivo de la solicitud, así como a integrar en éste, bajo su más estricta
                                        responsabilidad, toda la documentación e
                                        información que, conforme a las disposiciones aplicables, deba obrar en su poder
                                        y que se relacione directamente con la
                                        solicitud de aclaración que corresponda y sin incluir datos correspondientes a
                                        operaciones relacionadas con terceras
                                        personas;
                                        <br><br>
                                        Hasta en tanto la solicitud de aclaración de que se trate no quede resuelta de
                                        conformidad con el procedimiento señalado en
                                        este artículo, RedGirasol no podrá reportar como vencidas las cantidades sujetas
                                        a dicha aclaración a las sociedades de
                                        información crediticia.
                                        <br><br>
                                        Lo antes dispuesto es sin perjuicio del derecho del Comitente de acudir ante la
                                        Comisión Nacional para la Protección y
                                        Defensa de los Usuarios de Servicios Financieros o ante la autoridad
                                        jurisdiccional correspondiente conforme a las
                                        disposiciones legales aplicables y en los términos de este Contrato. Sin
                                        embargo, el procedimiento previsto en este artículo
                                        quedará sin efectos a partir de que el Comitente presente su demanda ante
                                        autoridad jurisdiccional o conduzca su
                                        reclamación en términos y plazos de la Ley de Protección y Defensa al Usuario de
                                        Servicios Financieros.
                                    </span>
                                </li><br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Gastos.</u></strong>
                                        La contraprestación aquí establecida, excluye todos los gastos y erogaciones
                                        relacionadas con las
                                        obligaciones del Comisionista conforme al presente Contrato, por lo tanto, el
                                        Comisionista podrá cobrar al Comitente
                                        cualquier otro gasto o erogación con la condición de que los mismos sean
                                        desglosados en su estado de cuenta mensual.
                                    </span>
                                </li> <br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong>
                                            <u>Caso fortuito y fuerza
                                                mayor.</u>
                                        </strong>
                                        Las Partes no serán responsables por la falta de realización de las actividades
                                        establecidas en el presente Contrato, cuando existan causas fuera de su control
                                        incluyendo órdenes de cualesquiera
                                        autoridades gubernamentales, civiles y/o militares, huelgas, inundaciones,
                                        epidemias, guerras, disturbios y demás causas
                                        análogas de caso fortuito o fuerza mayor. Lo anterior, en el entendido, además,
                                        que el Comisionista cuenta con un plan de
                                        continuidad de negocio, para el caso de contingencias operativas, para lo cual,
                                        el Comisionista no podrá excusar el
                                        incumplimiento de sus actividades bajo el presente Contrato por la actualización
                                        de los supuestos de contingencias
                                        operativas ahí previstas. En caso de actualizarse cualquiera de los supuestos
                                        previstos en esta Cláusula, el Comisionista
                                        deberá comunicar la existencia de dichas circunstancias al Comitente mediante
                                        los Medios Oficiales.
                                    </span>
                                </li> <br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Ejercicio de
                                                Derechos.</u></strong>
                                        El hecho de que alguna de las Partes no ejerza alguno de sus derechos derivados
                                        del presente
                                        contrato, no significa, ni deberá ser considerado, como una renuncia o pérdida
                                        de tales derechos.
                                    </span>
                                </li> <br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Correlación de
                                                documentos.</u></strong>
                                        Las Partes no podrán alegar desconocimiento o inaplicabilidad de alguna de las
                                        normas que en cualquier documento se establezcan, por lo que cualquier
                                        incumplimiento de los mismos será un
                                        incumplimiento a los términos y condiciones pactados en este Contrato, así
                                        también no podrá alegarse por las Partes el
                                        desconocimiento de la terminología empleada en este Contrato, sus accesorios y
                                        demás instrumentos legales relacionados
                                        con este Contrato y con la relación jurídica que sostengan las Partes por
                                        diversos acuerdos
                                    </span>
                                </li> <br>

                                <li class="dosdigitos" style="font-size:14px!important"><u>Firma.</u><span
                                        style="font-weight:normal">&nbsp;
                                        Las Partes acuerdan que para manifestar su consentimiento y firmar el presente
                                        Contrato podrán: (i) por
                                        medio de la Firma Electrónica Avanzada emitida por el Servicio de Administración
                                        Tributaria, y de conformidad con los
                                        artículos 2, 7, 8, 22 y demás aplicables de la Ley de Firma Electrónica
                                        Avanzada, (ii) por medio de cualquier firma
                                        electrónica simple de conformidad con las disposiciones del Código de Comercio o
                                        (iii) mediante clic en el recuadro de
                                        ACEPTO junto con el ingreso de la contraseña respectiva. Cualquiera de los
                                        métodos previamente anunciados tendrá como
                                        efecto el sometimiento expreso a las disposiciones del presente y por lo tanto
                                        surtirán efectos plenos para las Partes, frente
                                        a ellos mismos y frente a terceros. Así mismo, el Comitente expresamente
                                        renuncia a argumentar desconocimiento de la
                                        firma del presente</span></li> <br>

                                <li class="dosdigitos" style="font-size:14px!important"><u>Definciones.</u><span
                                        style="font-weight:normal">&nbsp;
                                        Las Partes expresamente acuerdan que los términos definidos establecidos en el
                                        Apéndice 1 del
                                        presente y usados en el clausulado del presente contrato, tendrán el alcance y
                                        significado que en los mismos se establece y
                                        no su significado literal. Asimismo, las Partes convienen en utilizar dichos
                                        términos en los Anexos del presente contrato
                                        que al efecto se celebren.
                                    </span></li> <br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Encabezados y Títulos de las
                                                cláusulas.</u></strong>
                                        Los encabezados y los títulos de las Cláusulas que aparecen en el presente
                                        Contrato, se han puesto con el exclusivo propósito de facilitar su lectura, por
                                        tanto, no necesariamente definen ni limitan el
                                        contenido de las mismas. Para efectos de interpretación de cada Cláusula deberá
                                        atenderse exclusivamente a su contenido
                                        y de ninguna manera a su título.
                                    </span>
                                </li> <br>

                                <li class="dosdigitos" style="font-size:14px!important">
                                    <span style="font-weight:normal">&nbsp;
                                        <strong><u>Legislación y jurisdicción
                                                aplicable.</u></strong>
                                        Las Partes se someten expresamente para la interpretación, cumplimiento y
                                        ejecución de este Contrato a lo establecido en la Ley FinTech, con la
                                        supletoriedad establecida en el artículo 10 de la misma,
                                        así como a la jurisdicción de los tribunales competentes de la ciudad de
                                        Monterrey, Nuevo León, México, respecto a
                                        cualquier acción o procedimiento, interpretación y cumplimiento de todo lo
                                        pactado en este Contrato, renunciando
                                        expresamente a cualquier jurisdicción que les pudiere corresponder en la
                                        actualidad o en el futuro por virtud de sus
                                        domicilios actuales o futuros o por cualquier otra razón.
                                        <br><br>
                                        El presente Contrato se firma de conformidad por las Partes que intervienen en
                                        el mismo, sin que mediare entre ellos vicio
                                        del consentimiento alguno, en la Ciudad de Monterrey, Nuevo León, el día
                                        [____________] del 2025.

                                    </span>
                                </li> <br> <br>
                            </ol>

                            <p style="text-align: center; font-weight: bold;">*-*-*-*-*-*</p>
                            <p>
                                Inscripción en el Registro de Contratos de Adhesión de la Comisión Nacional para la
                                Protección y Defensa
                                de los Usuarios de Servicios Financieros (CONDUSEF) No.
                                <strong>15895-452-041749/01-00560-0325</strong>
                            </p>

                            <br>
                            <div style="text-align: center"><strong><u>APÉNDICE 1</u></strong></div>
                            <div style="text-align: center"><strong>Definciones</strong></div>
                            <br>

                            Para los efectos de este contrato, los siguientes términos tendrán el significado que a
                            continuación se señalan: <br> <br>

                            <ol class="text-justify" style="font-weight:bold">
                                <li style="font-size:14px!important">"CONDUSEF": <span style="font-weight:normal">
                                        Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios
                                        Financieros.
                                    </span></li> <br>
                                <li style="font-size:14px!important">"Contrato": <span style="font-weight:normal">
                                        Significa el presente Contrato de Comisión, sus apéndices y anexos.
                                    </span></li> <br>
                                <li style="font-size:14px!important">"Crédito": <span style="font-weight:normal">
                                        Significa el contrato de crédito a ser celebrado por y entre los Inversionistas,
                                        representados por Red
                                        Girasol, con el Comitente para la adquisición del Proyecto.
                                    </span></li> <br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Documentos del Crédito":</strong>
                                        Significan todos y cada uno de los contratos y/o documentos que el Comisionista
                                        determine necesarios para documentar correctamente el otorgamiento del Monto,
                                        incluyendo pero sin limitar, a (i)
                                        el Crédito, (ii) un contrato de prenda entre los Inversionistas y el Comitente,
                                        (iii) un pagaré a ser firmado por el
                                        Comitente en favor de los Inversionistas; y (iv) en caso de financiamiento de
                                        Productos, un contrato de compraventa
                                        y prestación de servicios entre el Comitente y el proveedor seleccionado por el
                                        Comitente.
                                    </span>
                                </li> <br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>“Entidad Financiera”:</strong>
                                        a las sociedades controladoras y subcontroladoras de grupos financieros,
                                        instituciones de
                                        crédito, casas de bolsa, bolsas de valores, sociedades operadoras de fondos de
                                        inversión, sociedades distribuidoras
                                        de acciones de fondos de inversión, uniones de crédito, organizaciones
                                        auxiliares del crédito, casas de cambio,
                                        sociedades financieras de objeto múltiple, sociedades financieras populares,
                                        sociedades financieras comunitarias
                                        con niveles de operaciones I a IV, organismos de integración financiera rural,
                                        sociedades cooperativas de ahorro y
                                        préstamo con niveles de operación I a IV, instituciones para el depósito de
                                        valores, contrapartes centrales de valores,
                                        instituciones calificadoras de valores, sociedades de información crediticia,
                                        instituciones de seguros, instituciones
                                        de fianzas, sociedades mutualistas de seguros, administradoras de fondos para el
                                        retiro, así como otras instituciones
                                        y fideicomisos públicos que realicen actividades respecto de las cuales la CNBV,
                                        la CNSF o la CONSAR ejerzan
                                        facultades de supervisión.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Inversionistas":</strong>
                                        Es la o las personas físicas o morales que en conjunto financiarán el Crédito
                                        del Comisionista.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"IFC":</strong>
                                        institución de financiamiento colectivo
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Ley FinTech":</strong>
                                        Significa la Ley para Regular las Instituciones de Tecnología Financiera.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Medios Oficiales":</strong>
                                        Significa (i) el Sitio, (ii) el correo electrónico contacto@redgirasol.com,
                                        (iii) el teléfono
                                        establecido en la cláusula décimo segunda anterior y (iv) el estado de cuenta.
                                        El Comisionista además cuenta con
                                        las redes sociales oficiales señaladas en el Sitio.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Monto":</strong>
                                        Es la cantidad que el Comitente solicita sea financiada por los Inversionistas a
                                        través del Sitio.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>“Operaciones”:</strong>
                                        Significa recibir y publicar las solicitudes de operaciones de financiamiento
                                        colectivo de deuda,
                                        conforme el artículo 16, fracción I de la Ley Fintech.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Parte":</strong>
                                        Lo constituyen el Comisionista o el Comitente en forma indistinta y separada,
                                        según corresponda.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Partes":</strong>
                                        Lo constituyen el Comisionista y el Comitente en forma conjunta.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Persona":</strong>
                                        Significa e incluye personas físicas, corporaciones, sociedades, sociedades en
                                        comandita simple,
                                        sociedades de responsabilidad limitada, empresas de responsabilidad limitada,
                                        sociedades generales, sociedades
                                        anónimas, coinversiones, asociaciones, empresas, fundaciones de intereses
                                        privados, fideicomisos, bancos,
                                        fiduciarios, fideicomisos inmobiliarios, fideicomisos comerciales u otras
                                        organizaciones, sean personas morales o
                                        no, y todas las Autoridades Gubernamentales.

                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Productos":</strong>
                                        Son los activos propiedad del proveedor seleccionado por el Comitente, que el
                                        Comitente desea
                                        adquirir a través del Proyecto promovidos por el Comisionista con los
                                        Inversionistas.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Proyecto":</strong>
                                        Es el destino de los recursos solicitado por el Comitente para el financiamiento
                                        del Monto por
                                        Inversionistas y el pago de comisiones en favor del Comisionista, formalizado a
                                        través de los Documentos del
                                        Crédito.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"RedGirasol":</strong>
                                        Es la persona moral Red Girasol, S.A.P.I. de C.V., Institución de Financiamiento
                                        Colectivo.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Servicios":</strong>
                                        Serán todo tipo de actividades tendientes a conseguir financiamiento para el
                                        fondeo del Crédito, las
                                        cuales entre otras incluirán, la exhibición de la información del Comisionista,
                                        la realización de todo tipo de
                                        actividades mediante las cuales se promueva la inversión para el financiamiento
                                        del Crédito, incluyendo la entrega
                                        de todo tipo de información relativa a la transacción. Una vez que el Comitente
                                        realice el pago de las comisión por
                                        gastos de pre-originación, el Comisionista publicará el Proyecto para conseguir
                                        el Monto a financiarse con
                                        Inversionistas en el Sitio.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Sitio": </strong>
                                        <a href="https://www.redgirasol.com" target="_blank"
                                            rel="noopener noreferrer">www.redgirasol.com</a>
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"Territorio":</strong>
                                        Significa el territorio que comprenden los Estados Unidos Mexicanos.
                                    </span>
                                </li><br>
                                <li style="font-size:14px!important">
                                    <span style="font-weight:normal">
                                        <strong>"UNE":</strong>
                                        Unidad Especializada de Atención de Usuarios.
                                    </span>
                                </li><br>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import applicantInfoHelper from "@/helpers/applicantInfoHelper";

export default {
    name: "LatestApplicantMerchantContract",
    mixins: [applicantInfoHelper],
    props: {
        fillUserData: {
            type: Boolean
            , default: false
        },
        signedDate: { required: false, default: null },
        onlyCoverPage: { required: false, default: false }
    },
    computed: {
        userName: function () {
            if (this.fillUserData && this.user) {
                return this.user.complete_name;
            } else {
                return "";
            }
        },
        personTypeText: function () {
            if (this.fillUserData && this.user) {
                if (!this.userIsMoral(this.user.user_person_type)) {
                    return " por su propio derecho";
                }
            } else {
                return "";
            }
        },
        userLegalRepresentativeName: function () {
            if (this.fillUserData && this.user) {
                let name = "";
                if (this.userIsMoral(this.user.user_person_type)) {
                    name = " representada en este acto por " + this.user.legal_representative_name;
                }
                return name;
            } else {
                return "";
            }
        },
        userEmail: function () {
            if (this.fillUserData) {
                return (this.user === null) ? "-" : this.user.email;
            } else {
                return "";
            }
        },
        userPhone: function () {
            if (this.fillUserData) {
                return (this.user === null) ? "-" : this.user.phone;
            } else {
                return "";
            }
        },
        currentDate: function () {
            if (this.signedDate) {
                return this.signedDate;
            } else {
                return this.signatureFormatDate(new Date());
            }
        },
        currentClabe: function () {
            if (this.stpClabe) {
                return this.stpClabe;
            } else {
                return "____________";
            }
        },
    },
}
</script>

<style scoped>
#caratula {
    /* border: solid 1px #c2c2c2; */
    margin-bottom: 2rem;
    color: black;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
}

#caratula .vx-row {
    border: solid 1px #c2c2c2;
}

ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ol>li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

ol>li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

li ol>li {
    margin: 0;
}

li ol>li:before {
    content: counters(item, ".") " ";
}
</style>